import React from 'react'

export const BaldeSoldado500 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1522"
    height="848"
    viewBox="0 0 1522 848"
    {...props}
  >
    <defs>
      <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 267 145">
        <image width="267" height="145" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0005_Camada-7.png" />
      </pattern>
      <pattern id="pattern-2" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 64 57">
        <image width="64" height="57" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0006_Camada-8.png" />
      </pattern>
      <pattern id="pattern-3" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 31 31">
        <image width="31" height="31" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0008_Camada-10.png" />
      </pattern>
      <pattern id="pattern-4" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 72 97">
        <image width="72" height="97" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0004_Camada-6.png" />
      </pattern>
      <pattern id="pattern-5" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 101 149">
        <image width="101" height="149" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0000_Camada-2.png" />
      </pattern>
      <pattern id="pattern-6" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 60 95">
        <image width="60" height="95" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0001_Camada-3.png" />
      </pattern>
      <pattern id="pattern-7" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 32 22">
        <image width="32" height="22" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0002_Camada-4.png" />
      </pattern>
      <pattern id="pattern-8" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 218 225">
        <image width="218" height="225" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0003_Camada-5.png" />
      </pattern>
      <pattern id="pattern-9" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 92 41">
        <image width="92" height="41" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0010_Camada-12.png" />
      </pattern>
      <pattern id="pattern-10" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 70 37">
        <image width="70" height="37" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0011_Camada-13.png" />
      </pattern>
      <pattern id="pattern-11" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 109 118">
        <image width="109" height="118" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0014_Camada-16.png" />
      </pattern>
      <pattern id="pattern-12" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 114 93">
        <image width="114" height="93" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0015_Camada-17.png" />
      </pattern>
      <pattern id="pattern-13" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 84 75">
        <image width="84" height="75" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0017_Camada-19.png" />
      </pattern>
      <pattern id="pattern-14" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 40 23">
        <image width="40" height="23" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0018_Camada-20.png" />
      </pattern>
      <pattern id="pattern-15" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 33 41">
        <image width="33" height="41" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0016_Camada-18.png" />
      </pattern>
      <pattern id="pattern-16" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 84 132">
        <image width="84" height="132" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0020_Camada-22.png" />
      </pattern>
      <pattern id="pattern-17" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 144 236">
        <image width="144" height="236" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0021_Camada-23.png" />
      </pattern>
      <pattern id="pattern-18" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 146 69">
        <image width="146" height="69" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0023_Camada-25.png" />
      </pattern>
      <pattern id="pattern-19" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 11 28">
        <image width="11" height="28" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0022_Camada-24.png" />
      </pattern>
    </defs>
    <g
      id="Grupo_66"
      data-name="Grupo 66"
      transform="translate(-228 -116)"
      className="vista-g"
    >
      <image id="BS-500-PA01.501.33E.0401_0009_Camada-11" width="249" height="250" transform="translate(753 249)" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0009_Camada-11.png" />
      <rect id="BS-500-PA01.501.33E.0401_0005_Camada-7" width="281" height="153" transform="translate(402 250)" fill="url(#pattern)" />
      <rect id="BS-500-PA01.501.33E.0401_0006_Camada-8" width="68" height="60" transform="translate(596 192)" fill="url(#pattern-2)" />
      <rect id="BS-500-PA01.501.33E.0401_0008_Camada-10" width="32" height="33" transform="translate(708 112)" fill="url(#pattern-3)" />
      <image id="BS-500-PA01.501.33E.0401_0007_Camada-9" width="22" height="22" transform="translate(667 158)" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0007_Camada-9.png" />
      <rect id="BS-500-PA01.501.33E.0401_0004_Camada-6" width="75" height="102" transform="translate(270 337)" fill="url(#pattern-4)" />
      <rect id="BS-500-PA01.501.33E.0401_0000_Camada-2" width="106" height="157" transform="translate(215 612)" fill="url(#pattern-5)" />
      <rect id="BS-500-PA01.501.33E.0401_0001_Camada-3" width="63" height="100" transform="translate(414 670)" fill="url(#pattern-6)" />
      <rect id="BS-500-PA01.501.33E.0401_0002_Camada-4" width="34" height="23" transform="translate(543 725)" fill="url(#pattern-7)" />
      <rect id="BS-500-PA01.501.33E.0401_0003_Camada-5" width="229" height="237" transform="translate(463 437)" fill="url(#pattern-8)" />
      <rect id="BS-500-PA01.501.33E.0401_0010_Camada-12" width="97" height="43" transform="translate(1148 308)" fill="url(#pattern-9)" />
      <rect id="BS-500-PA01.501.33E.0401_0011_Camada-13" width="74" height="39" transform="translate(957 545)" fill="url(#pattern-10)" />
      <image id="BS-500-PA01.501.33E.0401_0012_Camada-14" width="381" height="429" transform="translate(856 501)" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0012_Camada-14.png" />
      <image id="BS-500-PA01.501.33E.0401_0013_Camada-15" width="62" height="121" transform="translate(1262 531)" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0013_Camada-15.png" />
      <image id="BS-500-PA01.501.33E.0401_0019_Camada-21" width="66" height="90" transform="translate(1251 378)" href="../baldesoldado-500/BS-500-PA01.501.33E.0401_0019_Camada-21.png" />
      <rect id="BS-500-PA01.501.33E.0401_0014_Camada-16" width="115" height="124" transform="translate(1291 845)" fill="url(#pattern-11)"
        data-name="Roda Pneumática Ø500"
        data-url="https://shopirbal.com/pt/produto/roda-pneumatica-500/" />
      <rect id="BS-500-PA01.501.33E.0401_0015_Camada-17" width="120" height="98" transform="translate(1352 661)" fill="url(#pattern-12)" />
      <rect id="BS-500-PA01.501.33E.0401_0017_Camada-19" width="89" height="79" transform="translate(1563 577)" fill="url(#pattern-13)"
        data-name="Motor ELE 3,00HP – 50HZ – 220V"
        data-url="https://shopirbal.com/pt/produto/motor-ele-300hp-50hz-220v/" />
      <rect id="BS-500-PA01.501.33E.0401_0018_Camada-20" width="42" height="24" transform="translate(1663 662)" fill="url(#pattern-14)"
        data-name="Condensador 3HP"
        data-url="https://shopirbal.com/pt/produto/condensador-3hp/" />
      <rect id="BS-500-PA01.501.33E.0401_0016_Camada-18" width="35" height="43" transform="translate(1548 780)" fill="url(#pattern-15)"
        data-name="Interruptor B3+B4"
        data-url="https://shopirbal.com/pt/produto/interruptor-b3b4/" />
      <rect id="BS-500-PA01.501.33E.0401_0020_Camada-22" width="88" height="139" transform="translate(1365 392)" fill="url(#pattern-16)" />
      <rect id="BS-500-PA01.501.33E.0401_0021_Camada-23" width="151" height="248" transform="translate(1466 283)" fill="url(#pattern-17)" />
      <rect id="BS-500-PA01.501.33E.0401_0023_Camada-25" width="153" height="73" transform="translate(1468 192)" fill="url(#pattern-18)" />
      <rect id="BS-500-PA01.501.33E.0401_0022_Camada-24" width="11" height="29" transform="translate(1669 295)" fill="url(#pattern-19)" />
    </g>
  </svg>
)
